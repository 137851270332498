import { ApiService } from '@core/http';
import { getSvg } from 'src/app/core/helpers/get-svg';
import { deconstructFormula } from 'src/app/project/components/formula-builder/utils/deconstruct-formula';
import {
  EFormulaPieceType,
  TFormulaPiece,
} from 'src/app/project/components/formula-builder/utils/formula-input.type';
import { translate } from 'src/app/project/features/translate/translate';
import { getCustomFieldIcon } from '../../site/site-options/utils/get-field-icon';
import { TWorkspaceCustomFields } from '../custom-fields.model';

export function setFormulaTooltipTitle(
  customFieldId: string,
  customFields: TWorkspaceCustomFields,
  apiService: ApiService,
): Promise<string> {
  return new Promise((resolve) => {
    const formulaPieces = deconstructFormula(customFields[customFieldId].formula);
    const formulaHidden = formulaPieces.some(
      (piece) => piece.type === EFormulaPieceType.CUSTOM_FIELD && !customFields?.[piece.value],
    );

    if (formulaHidden) {
      resolve(translate('formula_result'));

      return;
    }

    const operatorsList = formulaPieces.map((piece) =>
      getPieceHTML(piece, customFields, apiService),
    );

    Promise.all(operatorsList).then((operatorsListResponse) => {
      const tooltip = `<div class="tooltipFormula">
      <div class="tooltipFormula__operator">f</div>
      ${operatorsListResponse.join('')}
    </div>`;

      resolve(tooltip);
    });
  });
}

function getPieceHTML(
  piece: TFormulaPiece,
  customFields: TWorkspaceCustomFields,
  apiService: ApiService,
): Promise<string> {
  switch (piece.type) {
    case EFormulaPieceType.OPERATOR:
      return Promise.resolve(`<div class="tooltipFormula__operator">${piece.value}</div>`);
    case EFormulaPieceType.CUSTOM_FIELD: {
      return new Promise((resolve) => {
        const customField = customFields?.[piece.value];
        const fieldIcon = getCustomFieldIcon(piece.value);

        getSvg(apiService, fieldIcon).then((svg) => {
          resolve(`<div class="tooltipFormula__customField">
            ${svg}
            <span>${customField.label}</span>
          </div>`);
        });
      });
    }
    default:
      return Promise.resolve('');
  }
}
