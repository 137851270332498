import { Component, Input, OnChanges } from '@angular/core';
import { ApiService } from '@core/http';
import { TPointCustomField } from '@project/view-models';
import { isNumber } from 'lodash';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { getDecimalPlacesValue } from 'src/app/project/modules/custom-fields/custom-field-numbers/utils/getDecimalPlacesValue';
import { ECustomFieldType } from 'src/app/project/modules/custom-fields/custom-field-types-enums';
import { TCustomField } from 'src/app/project/modules/custom-fields/custom-fields.model';
import { CustomFieldsService } from 'src/app/project/modules/custom-fields/custom-fields.service';
import { setFormulaTooltipTitle } from 'src/app/project/modules/custom-fields/utils/create-formula-tooltip';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';

@Component({
  selector: 'pp-point-fields-formula',
  templateUrl: './point-fields-formula.component.html',
  styleUrl: './point-fields-formula.component.scss',
})
export class PointFieldsFormulaComponent implements OnChanges {
  @Input() ppWorkspaceId: string;
  @Input() ppFieldId: string;
  @Input() ppFieldLabel: string;
  @Input() ppField: TPointCustomField;
  @Input() ppFieldCurrencyCode: string;
  @Input() ppFieldCurrencySymbol: string;
  @Input() ppNew: boolean;

  value: string = '';
  EIconPath = EIconPath;
  unitSymbol: string;
  tooltipTitle: string;

  private decimalPlaces: number;
  private showCommas: boolean;
  private customField: TCustomField;

  constructor(
    private customFieldsService: CustomFieldsService,
    private translationPipe: TranslationPipe,
    private apiService: ApiService,
  ) {}

  ngOnChanges(): void {
    this.customField =
      this.customFieldsService.getCustomFields()[this.ppWorkspaceId]?.[this.ppFieldId];

    if (this.customField) {
      this.setFormulaDisplayData();
      this.setTooltipTitle();

      this.value = this.formatValue(this.ppField.value);
    }
  }

  private setFormulaDisplayData(): void {
    switch (this.customField.outputType) {
      case ECustomFieldType.PERCENTAGE:
        this.decimalPlaces = 0;
        this.unitSymbol = '%';
        this.showCommas = false;
        break;
      case ECustomFieldType.NUMBERS:
        this.unitSymbol = this.customField.unit;
        this.showCommas = this.customField.showCommas;
        this.decimalPlaces = getDecimalPlacesValue(this.customField.decimalPlaces);

        break;
      case ECustomFieldType.COST:
        this.unitSymbol = this.ppFieldCurrencyCode + ' ' + this.ppFieldCurrencySymbol;
        this.showCommas = this.customField.showCommas;
        this.decimalPlaces = 2;

        break;
    }
  }

  private formatValue(value: string): string {
    if (this.ppNew) {
      return '';
    }

    if (value === null) {
      return this.translationPipe.transform('unable_to_calculate_formula');
    }

    let formattedValue = value;

    if (isNumber(this.decimalPlaces)) {
      formattedValue = Number(value).toFixed(this.decimalPlaces);
    }

    if (this.showCommas) {
      formattedValue = formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    return formattedValue;
  }

  private setTooltipTitle(): void {
    setFormulaTooltipTitle(
      this.ppFieldId,
      this.customFieldsService.getCustomFields()[this.ppWorkspaceId],
      this.apiService,
    ).then((tooltip) => {
      this.tooltipTitle = tooltip;
    });
  }
}
