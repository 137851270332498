<div class="full-height">
  <pp-supported-browsers-bar></pp-supported-browsers-bar>
  <pp-action-bar></pp-action-bar>
  <pp-reminder-alert></pp-reminder-alert>
  <pp-top-bar [ppFromSite]="true"></pp-top-bar>
  <pp-side-panel></pp-side-panel>

  <div
    class="site"
    id="site"
    [class.site--no-sidebar]="sidePanel.hidden"
    [class.site--down]="ui.actionBar"
  >
    <div id="siteHeader" data-m-target="Work list menu" class="site__header">
      <pp-site-options
        class="siteOptionsComponent"
        [ppDataFetched]="dataFetched?.dataFetched"
      ></pp-site-options>
    </div>

    <div class="site__body" [class.site__body--hidden]="workspace?.hidden" #body>
      <div
        class="site__table-wrapper"
        [ngStyle]="{
          'width.%': siteElementSizes.sitePlanWidth ? 100 - siteElementSizes.sitePlanWidth : 50,
        }"
        data-m-target="Work list table"
        [class.site__table-wrapper--expanded]="!site.sitePlanVisible"
        [class.site__table-wrapper--hidden]="site.sitePlanVisible && site.sitePlanFullWidth"
      >
        <pp-site-table
          *ngIf="workspaceId && workspace && !processing"
          [ppWorkspaceId]="workspaceId"
          [ppHidden]="site.sitePlanVisible && site.sitePlanFullWidth"
          [ppWidth]="siteElementSizes.siteTableWidthPercentage"
          [ppFullWidth]="!site.sitePlanVisible"
        ></pp-site-table>

        <div
          class="site__table-placeholderWrapper"
          [ngStyle]="{
            'width.%': siteElementSizes.sitePlanWidth ? 100 - siteElementSizes.sitePlanWidth : 50,
          }"
          [class.site__table-wrapper--expanded]="!site.sitePlanVisible"
          *ngIf="!tableFetched?.dataFetched || !dataFetched?.dataFetched"
        >
          <pp-site-table-placeholder
            [ppPlanWidth]="siteElementSizes.sitePlanWidth"
          ></pp-site-table-placeholder>
        </div>
      </div>

      <div
        class="site__site-plan"
        [ngStyle]="{
          'width.%': siteElementSizes.sitePlanWidth ? siteElementSizes.sitePlanWidth : 50,
        }"
        [ngClass]="{ 'site__site-plan--hidden': !site.sitePlanVisible }"
        [class.site__site-plan--full-width]="site.sitePlanVisible && site.sitePlanFullWidth"
      >
        <pp-column-resizer
          *ngIf="site.sitePlanVisible && !site.sitePlanFullWidth"
          (ppDrag)="onPlanResizerDrag($event)"
          (ppDrop)="onPlanResizerDrop()"
        ></pp-column-resizer>

        <pp-plan
          [ppWidth]="siteElementSizes.sitePlanWidth"
          [ppModule]="EPlanModule.SITE"
          [ppWorkspaceId]="workspaceId"
          *ngIf="dataFetched?.dataFetched && site.sitePlanVisible"
        ></pp-plan>

        <router-outlet></router-outlet>
      </div>
    </div>

    <div class="site__hidden__info" *ngIf="workspace?.hidden">
      <span class="site__hidden__info__text">
        {{ 'site_hidden_in_settings_1' | ppTranslate }}
        <span class="site__hidden__info__text--link" (click)="openUserSettings()">
          {{ 'site_hidden_in_settings_2' | ppTranslate }}
        </span>
      </span>
    </div>
  </div>
</div>

<router-outlet name="full"></router-outlet>
